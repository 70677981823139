import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ProductService,
  AlertService,
  BannersService,
  CategoryService,
} from "../_services";
import { Observable } from "rxjs/Observable";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { toArray } from "rxjs/operators";

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;

@Component({
  selector: "app-product-view",
  templateUrl: "./product-view.component.html",
  styleUrls: ["./product-view.component.css"],
})
export class ProductViewComponent implements OnInit {
  mainCategory = {
    displayKey: "title", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: "250px", // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select main category", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No main category found", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search main category",
    padding: "15px", // label thats displayed in search input,

    selectAllBtnText: "Select",
    searchOnKey: "title", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  subCategory = {
    displayKey: "title", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: "250px", // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select sub category", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No sub category found", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search sub category",
    padding: "15px", // label thats displayed in search input,

    selectAllBtnText: "Select",
    searchOnKey: "title", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(
    private route: ActivatedRoute,
    private bannerService: BannersService,
    private cat: CategoryService,
    private formBuilder: FormBuilder,
    private pro: ProductService,
    private toastr: ToastrService
  ) {}
  routeSub: any;

  main$: Observable<any[]>;

  sub$: Observable<any[]>;

  product$: Observable<any[]>;

  main_list: any;

  main: any;
  sub: any;

  productForm: FormGroup;

  images: any;

  submitted = false;

  media_domain = `${environment.media_domain}`;

  weblink = `${environment.WEBLINK}`;

  productSingle$: Observable<any[]>;

  curUrl: any;

  men_check: any;
  women_check: any;
  kids_check: any;
  in_stock: any;

  branchwidth_limit = `${environment.prodImgWidth}`;
  branchheight_limit = `${environment.prodImgHeight}`;

  new_promofile: any;
  images_array: any;

  ngOnInit() {
    this.curUrl = localStorage.getItem("currentUrl");

    this.productForm = this.formBuilder.group({
      sku: ["", [Validators.required]],
      p_name: ["", [Validators.required]],
      c_grades: ["", [Validators.required]],
      weight: ["", [Validators.required]],
      q_weight: ["", [Validators.required]],
      purity: ["", [Validators.required]],
      metal: ["", [Validators.required]],
      stone_weight: ["", [Validators.required]],
      stone_value: ["", [Validators.required]],
      stone_details: ["", [Validators.required]],
      m_charges: ["", [Validators.required]],
      mc_per_gram: [""],
      cents: ["", [Validators.required]],
      daimond_value: ["", [Validators.required]],
      include_backchain: [""],
      cost: ["", [Validators.required]],
      detailed_desc: [""],
      desc: [""],
      max_shipping_days: ["", [Validators.required]],
      certifications: ["", [Validators.required]],
      cut: ["", [Validators.required]],
      size: [""],
      lock_type: [""],
      lace: [""],
      lace_cost: [""],
      chain_weight: [""],
      chain_purity: [""],
      chain_metal: [""],
      chain_cost: [""],
      chain_making_charges_percentage: [""],
      chain_making_charges: [""],
      chain_certification_charges: [""],
      color: ["", [Validators.required]],
      clarity: ["", [Validators.required]],
      collection: [""],
      occassion: ["", [Validators.required]],
      mainCat: ["", [Validators.required]],
      subCat: ["", [Validators.required]],
      other_tags: ["", [Validators.required]],
      note: [""],
      certification_charges: ["", [Validators.required]],
      making_type: ["1", [Validators.required]],
    });

    this.cat.getMainCat().subscribe(
      (data) => {
        console.log(data);
        this.main$ = data.data.main;
      },
      (error) => {
        console.error(error);
      }
    );

    this.cat.getSubCategory().subscribe(
      (data) => {
        console.log(data);
        this.sub$ = data.data.main;
      },
      (error) => {
        console.error(error);
      }
    );

    this.routeSub = this.route.params.subscribe((params) => {
      console.log(params["id"]);

      this.pro.getsinglePro(params["id"]).subscribe(
        (data) => {
          console.log(data);
          this.productSingle$ = data.data;
          this.images = data.data[0].pro_images[0].image
            ? data.data[0].pro_images[0].image
            : data.data[0].pro_images[1].image;
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  changeMakingAmount(e) {
    if (e.target.value == "2") {
      this.productForm.patchValue({
        m_charges: this.productSingle$[0].pro_details.making_charges_percentage,
      });
    } else {
      this.productForm.patchValue({
        m_charges: this.productSingle$[0].pro_details.making_amount,
      });
    }
  }

  handleFileInput(event: any, files: FileList) {
    this.images_array = [];

    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        console.log(img);
        var imgWidth = parseInt(that.branchwidth_limit);
        var imgHeight = parseInt(that.branchheight_limit);
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error(
            "Please upload image of " + imgWidth + "*" + imgHeight
          );
          // that.imageForm.get('image').setValue('');

          return false;
        } else {
          $("#direct").attr("src", window.URL.createObjectURL(files.item(0)));
          that.new_promofile = files.item(0);
        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));
  }

  get f() {
    return this.productForm.controls;
  }
  productSubmit() {
    this.submitted = true;
    // console.log(this.productForm)
    // if (this.productForm.invalid) {
    //   return false;
    // }

    var men = $("#men").prop("checked");
    var women = $("#women").prop("checked");
    var kids = $("#kids").prop("checked");

    var stock = $("#stock").prop("checked");
    var that = this;
    alertify.confirm(
      "Are you sure want to update this product?",
      function () {
        that.pro
          .editProduct(
            that.productSingle$[0]._id,
            that.f.sku.value,
            that.f.p_name.value,
            that.f.c_grades.value,
            that.f.weight.value,
            that.f.q_weight.value,
            that.f.purity.value,
            that.f.metal.value,

            that.f.m_charges.value,

            that.f.mc_per_gram.value,
            that.f.cents.value,
            that.f.daimond_value.value,
            that.f.stone_details.value,
            that.f.cost.value,
            that.f.detailed_desc.value,
            that.f.max_shipping_days.value,
            that.f.certifications.value,
            that.f.cut.value,
            that.f.size.value,
            that.f.lock_type.value,
            that.f.lace.value,
            that.f.lace_cost.value,
            that.f.chain_weight.value,
            that.f.chain_purity.value,
            that.f.chain_metal.value,
            that.f.chain_cost.value,
            that.f.chain_making_charges_percentage.value,
            that.f.chain_making_charges.value,
            that.f.chain_certification_charges.value,
            that.f.color.value,
            that.f.clarity.value,
            that.f.collection.value,
            that.f.occassion.value,
            that.f.mainCat.value.title,
            that.f.subCat.value.title,

            that.f.desc.value,
            that.f.stone_weight.value,
            that.f.stone_value.value,

            that.f.stone_details.value,
            that.f.other_tags.value,
            that.f.note.value,

            men,
            women,
            kids,
            stock,
            that.new_promofile,

            that.f.certification_charges.value,
            that.f.making_type.value,
            that.f.include_backchain.value
          )
          .subscribe(
            (data) => {
              console.log(data);

              if (data.status.code == 0) {
                that.toastr.success("Successfully updated");
                location.reload();
              } else {
                that.toastr.error(data.status.message);
              }
            },
            (error) => {
              console.error(error);
            }
          );
      },
      function () {
        that.toastr.error("Action cancelled");
      }
    );
  }

  goBack() {
    this.curUrl = localStorage.getItem("currentUrl");
    location.href = this.curUrl;
  }

  selectmainCategory(e) {
    console.log(e);
    this.main = e.value.title;
  }

  selectSub(e) {
    this.sub = e.value.title;
  }

  editSingleOrder() {
    $(".fff").show();
    $(".ccc").hide();

    /* var id = this.productSingle$[0]['m_category_id'];
     var main_cat;
     this.main$.forEach(function (val) {
       console.log(val['_id'] == id)
       if (val['_id'] == id) {
         main_cat = val['title'];
         this.main = val['title'];
       }
     });
 
 
 
     var ids = this.productSingle$[0]['s_category_id'];
     var sub_cat;
     this.sub$.forEach(function (val) {
       console.log(val['_id'] == ids)
       if (val['_id'] == id) {
         sub_cat = val['title'];
         this.sub = val['title'];
       }
     });*/

    this.men_check = this.productSingle$[0].pro_details.men;
    this.women_check = this.productSingle$[0].pro_details.women;
    this.kids_check = this.productSingle$[0].pro_details.kids;
    this.in_stock = this.productSingle$[0].in_stock;

    this.productForm.patchValue({
      sku: this.productSingle$[0].SKU,
      p_name: this.productSingle$[0].name,

      stone_weight: this.productSingle$[0].pro_details.stone_weight,
      stone_value: this.productSingle$[0].pro_details.stone_value,

      c_grades: this.productSingle$[0].pro_details.category_grade,
      weight: this.productSingle$[0].pro_details.weight,
      q_weight: this.productSingle$[0].pro_details.q_weight,
      purity: this.productSingle$[0].pro_details.purity,
      metal: this.productSingle$[0].pro_details.metal,

      m_charges:
        this.productSingle$[0].pro_details.making_type == "2"
          ? this.productSingle$[0].pro_details.making_charges_percentage
          : this.productSingle$[0].pro_details.making_amount,

      mc_per_gram: this.productSingle$[0].pro_details.mc_per_gram,
      cents: this.productSingle$[0].pro_details.cents,
      daimond_value: this.productSingle$[0].pro_details.daimond,
      stones: this.productSingle$[0].pro_details.stone,
      cost: this.productSingle$[0].pro_details.cost,
      detailed_desc: this.productSingle$[0].pro_details.detailed_description,
      desc: this.productSingle$[0].pro_details.description,
      max_shipping_days: this.productSingle$[0].pro_details.max,
      certifications: this.productSingle$[0].pro_details.certification,
      cut: this.productSingle$[0].pro_details.cut,
      size: this.productSingle$[0].pro_details.size,
      lock_type: this.productSingle$[0].pro_details.lock_type
        ? this.productSingle$[0].pro_details.lock_type
        : "null",
      lace: this.productSingle$[0].pro_details.lace
        ? this.productSingle$[0].pro_details.lace
        : "null",
      lace_cost: this.productSingle$[0].pro_details.lace_cost
        ? this.productSingle$[0].pro_details.lace_cost
        : 0,
      chain_weight: this.productSingle$[0].pro_details.chain_weight
        ? this.productSingle$[0].pro_details.chain_weight
        : "null",
      chain_making_charges: this.productSingle$[0].pro_details
        .chain_making_charges
        ? this.productSingle$[0].pro_details.chain_making_charges
        : 0,
      chain_purity: this.productSingle$[0].pro_details.chain_purity
        ? this.productSingle$[0].pro_details.chain_purity
        : "null",
      chain_cost: this.productSingle$[0].pro_details.chain_cost
        ? this.productSingle$[0].pro_details.chain_cost
        : 0,
      color: this.productSingle$[0].pro_details.colour,
      clarity: this.productSingle$[0].pro_details.clarity,
      collection: this.productSingle$[0].pro_details.collection,
      occassion: this.productSingle$[0].pro_details.occasion
        ? this.productSingle$[0].pro_details.occasion
        : "null",
      mainCat: this.productSingle$[0].main_cat,
      subCat: this.productSingle$[0].sub_cat,

      stone_details: this.productSingle$[0].pro_details.stone_details,
      other_tags: this.productSingle$[0].pro_details.other_tags,
      note: this.productSingle$[0].pro_details.note,

      certification_charges:
        this.productSingle$[0].pro_details.certification_charges,

      making_type: this.productSingle$[0].pro_details.making_type,
      include_backchain: this.productSingle$[0].pro_details.include_backchain,

      //certification_charges
    });
  }

  cancelOp() {
    $(".fff").hide();
    $(".ccc").show();
  }
}
