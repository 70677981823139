<site-header></site-header>
<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
  google-chart {
    width: 100%;
  }
</style>
<!-- Page -->
<div class="page" style="min-height: 500px">
  <div class="page-content container-fluid">
    <div class="row" data-plugin="matchHeight" data-by-row="true">
      <div class="card_page flt">
        <div class="card_alert flt">
          <div class="col-sm-12">
            <div class="alert alert-success ls" *ngIf="role == '99'">
              <strong>Welcome!</strong> Admin.
            </div>
            <div
              class="alert alert-success"
              *ngIf="role == '100' && status == '1'"
            >
              <strong>Verified!</strong> Welcome {{ adminName }}.
            </div>
            <div
              class="alert alert-warning"
              *ngIf="role == '100' && status == '0'"
            >
              <strong>Verification Pending!</strong> Welcome {{ adminName }}.
            </div>
          </div>
        </div>
        <div class="cards_container flt">
          <div class="row view_card">
            <div class="col-sm-12">
              <div class="col-sm-3" *ngIf="role == '99'">
                <a href="/users">
                  <div class="card_box card_box1 flt">
                    <div class="card_count" data-count="1000"></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-2">
                          <i class="fas fa-users"> </i>
                        </div>
                        <div class="col-md-10">
                          <span class="float-right view_txt">Total User </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="float-right card_countnum">{{
                            user
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3" *ngIf="role == '99'">
                <a href="/orders">
                  <div class="card_box card_box2 flt">
                    <div class="card_count" data-count="1000"></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-2">
                          <i class="fas fa-shopping-cart"></i>
                        </div>
                        <div class="col-md-10">
                          <span class="float-right view_txt">Total Order</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="float-right card_countnum">{{
                            orders ? orders : 0
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3" *ngIf="role == '99'">
                <a href="/orders">
                  <div class="card_box card_box3 flt">
                    <div class="card_count" data-count="1000"></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-2">
                          <i class="fas fa-bars"></i>
                        </div>
                        <div class="col-md-10">
                          <span class="float-right view_txt">Order Amount</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="float-right card_countnum">{{
                            ord_amount ? ord_amount : "0.00"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <!-- <div class="col-sm-3" *ngIf="role=='99'">
                                <a href="/product">
                                    <div class="card_box card_box4 flt">
                                        <div class="card_count" data-count="1000">
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <i class="fas fa-tasks"></i>
                                                </div>
                                                <div class="col-md-10">
                                                    <span class="float-right view_txt">Total Products
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <span class="float-right card_countnum">{{products ? products : 0}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> -->
              <!-- most clicked products -->
              <!-- <div class="col-sm-3" *ngIf="role=='99'">
                                <a href="/most-viewed">
                                    <div class="card_box card_box9 flt">
                                        <div class="card_count" data-count="1000">
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <img src="/assets/one-level/images/most-clicked.png">
                                                </div>
                                                <div class="col-md-10">
                                                    <span class="float-right view_txt">Most Clicked Products
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <span class="float-right card_countnum">{{mostclick ? mostclick : '0'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> -->
            </div>
          </div>
          <div class="row view_table">
            <div class="col-sm-12">
              <div class="col-sm-6" *ngIf="role == '99'">
                <div class="rate_box">
                  <table style="text-align: left" class="table table-striped">
                    <thead>
                      <tr>
                        <th colspan="2">
                          Daily Rates
                          <span class="float-right"
                            ><img
                              src="/assets/one-level/images/view-table-right.png"
                          /></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="col">
                          <img src="/assets/one-level/images/gold-img-1.png" />
                          Gold 22 carat
                        </th>
                        <td>{{ gold_22 }}/-</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          <img src="/assets/one-level/images/gold-img-2.png" />
                          Gold 18 carat
                        </th>
                        <td>{{ gold_18 }}/-</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          <img
                            src="/assets/one-level/images/silver-img-1.png"
                          />
                          Silver
                        </th>
                        <td>{{ silver }}/-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
