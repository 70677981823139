<site-header></site-header>
<!-- <ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading> -->
<div class="page">
  <div class="page-header">
    <h1 class="page-title">Products</h1>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active">Products</li>
    </ol>
  </div>
  <div class="page-content container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <!-- Panel Kitchen Sink -->
        <div class="panel">
          <div class="abharan_main">
            <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                                Products
                            </h3>
                        </header> -->
            <div class="abharan_upload">
              <div class="row upload_area">
                <div class="col-sm-6">
                  <div class="upload_left">
                    <ul class="upload_list">
                      <li><b>Upload Excel</b></li>
                      <li>
                        <div class="upload-input">
                          <input
                            type="file"
                            (change)="uploadExcel($event, $event.target.files)"
                            id="upload_file"
                            name="file-upload"
                          />
                        </div>
                      </li>
                      <li>
                        <button class="upload_btn" (click)="uploadExcelFile()">
                          Upload
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-6">
                  <!-- <div class="col-sm-3">
                                        <ngx-select-dropdown #id [config]="mainCat" [options]="main$" [multiple]=true (change)="selectmainCategory($event)"
                                        class="clientSelect" style="height:35px;">
                                        </ngx-select-dropdown>
                                    </div>
                                    
                                    <div class="col-sm-3">
                                        <ngx-select-dropdown #id [config]="subCategory" [options]="sub$" [multiple]=true
                                        (change)="selectsubCategory($event)" class="clientSelect" style="height:35px;">
                                        </ngx-select-dropdown>
                                    </div> -->
                  <div class="upload_right">
                    <ul>
                      <li class="diamond">
                        <a (click)="download()" style="cursor: pointer">
                          <i class="fas fa-download"></i> Download Template
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel-body">
              <div class="col-md-3">
                <span class="bold allprod_count">
                  {{
                    stock_filter == "-1"
                      ? "Out of Stock Products count"
                      : stock_filter == "1"
                      ? "In Stock Products count"
                      : "All Products count"
                  }}
                  {{ total }}</span
                >
              </div>
              <div class="tb-wrapper">
                <div class="row product_dropdown prod_comparea">
                  <div class="col-md-2">
                    <select
                      class="form-control"
                      (change)="searchFilter($event.target.value, 'stock')"
                    >
                      <option disabled>Select Stock</option>
                      <option value="">All</option>
                      <option value="1">In stock</option>
                      <option value="-1">Out of stock</option>
                    </select>
                  </div>

                  <div class="col-md-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="SKU"
                      (keyup)="searchFilter($event.target.value, 'sku')"
                    />
                  </div>

                  <div class="col-md-2">
                    <select
                      class="form-control"
                      (change)="searchFilter($event.target.value, 'main_cat')"
                    >
                      <option disabled>Select Main Category</option>
                      <option value="">All</option>
                      <option *ngFor="let val of main$" value="{{ val._id }}">
                        {{ val.title }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-2">
                    <select
                      class="form-control"
                      (change)="searchFilter($event.target.value, 'sub_cat')"
                    >
                      <option disabled>Select Sub Category</option>
                      <option value="">All</option>
                      <option *ngFor="let val of sub$" value="{{ val._id }}">
                        {{ val.title }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 dwnd_prod">
                    <button
                      (click)="reportDown()"
                      class="btn btn-primary waves-effect waves-classic down-link"
                      [disabled]="total == 0 ? true : false"
                    >
                      <i class="site-menu-icon md-download"></i>Download
                      Products
                    </button>
                    <a
                      href="javascript::void(0)"
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary ml-auto download_template"
                      ><i class="fa fa-download" aria-hidden="true"></i>Download
                      Report</a
                    >
                  </div>
                </div>
                <div class="table-responsive main_table">
                  <table
                    cellspacing="0"
                    id="table"
                    class="table table-hover dataTable table-striped w-full table-bordered product_table"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>Sl.No.</th>
                        <th>SKU</th>
                        <th>MAIN CATEGORY</th>
                        <th>SUB CATEGORY</th>
                        <th>PRODUCT NAME</th>
                        <th>CATEGORY GRADES</th>
                        <th>WEIGHT</th>
                        <th>QUOTE WEIGHT</th>
                        <th width="120px">Product Total(Included GST)</th>
                        <th>View/Edit</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pro of products$; let i = index">
                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                        <td>
                          <ng-container *ngIf="pro.SKU; else no_SKU">
                            <a
                              style="color: blue"
                              href="{{ weblink }}/product-details/{{ pro.SKU }}"
                              target="_blank"
                            >
                              {{ pro.SKU }}
                            </a>
                          </ng-container>
                          <ng-template #no_SKU>NA</ng-template>
                        </td>
                        <td>
                          <ng-container
                            *ngIf="pro.main_cat.title; else no_title"
                          >
                            {{ pro.main_cat.title }}</ng-container
                          >
                          <ng-template #no_title>NA</ng-template>
                        </td>
                        <td>
                          <ng-container
                            *ngIf="pro.sub_cat.title; else no_title"
                          >
                            {{ pro.sub_cat.title }}</ng-container
                          >
                          <ng-template #no_title>NA</ng-template>
                        </td>
                        <td>{{ pro.name }}</td>
                        <td>
                          <ng-container
                            *ngIf="
                              pro.pro_details.category_grade;
                              else no_c_grades
                            "
                          >
                            {{ pro.pro_details.category_grade }}</ng-container
                          >
                          <ng-template #no_c_grades>NA</ng-template>
                        </td>
                        <td>
                          <ng-container
                            *ngIf="pro.pro_details.weight; else no_weight"
                          >
                            {{ pro.pro_details.weight }}</ng-container
                          >
                          <ng-template #no_weight>0</ng-template>
                        </td>

                        <td>
                          <ng-container
                            *ngIf="pro.pro_details.quote; else no_q_weight"
                          >
                            {{ pro.pro_details.q_weight }}</ng-container
                          >
                          <ng-template #no_q_weight>0</ng-template>
                        </td>
                        <td>
                          <ng-container *ngIf="pro.c_total; else no_cost">
                            {{ pro.c_total }}</ng-container
                          >
                          <ng-template #no_cost>0</ng-template>
                        </td>
                        <td>
                          <a href="/product-view/{{ pro._id }}" class="view_btn"
                            >View/Edit</a
                          >
                        </td>
                        <td>
                          <a
                            (click)="deleteProduct($event, pro._id)"
                            class="view_btn delete"
                            >Delete</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Panel Kitchen Sink -->
      </div>
    </div>
  </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
  .example-wrap {
    margin-bottom: 30px;
  }

  .disabledTable {
    pointer-events: none;
    opacity: 0.4;
  }
</style>
